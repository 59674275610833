<!-- 商品规格 -->
<template>
	<div class="bg-white px-3" style="margin: -20px;margin-top: -1rem; margin-bottom: 0!important;">
		<!-- 头部 搜索 -->
		<button-search class="pt-3" :showSearch="false">
			<!-- 左边按钮 -->
			<template #left>

				<el-button type="success" size="mini" @click="openModel(false)">添加规格</el-button>
				<el-button type="danger" size="mini" @click="deleteAll">批量删除</el-button>
			</template>
		</button-search>

		<!-- 商品列表 -->
		<el-table :data="tableData" style="width: 100%" border class="mt-3" @selection-change="handleSelectionChange">
			<el-table-column type="selection" width="45" align="center">
			</el-table-column>

			<!-- 规格名称 -->
			<el-table-column prop="name" label="规格名称">
			</el-table-column>

			<!-- 规格值 -->
			<el-table-column prop="default" label="规格值" align="center" width="380">
			</el-table-column>

			<!-- 排序 -->
			<el-table-column prop="order" label="排序" align="center">
			</el-table-column>

			<!-- 状态 -->
			<el-table-column prop="status" label="状态" align="center">
				<template slot-scope="scope">
					<el-button :type="scope.row.status ? 'success' : 'danger'" size="mini" plain @click="changeStatus(scope.row)">{{scope.row.status ? '启用' : '禁用'}}</el-button>
				</template>
			</el-table-column>

			<!-- 操作 -->
			<el-table-column label="操作" align="center" width="150">
				<!-- scope拿到当前位置的索引 -->
				<template slot-scope="scope">
					<el-button-group>
						<el-button type="primary" size="mini" plain @click="openModel(scope)">修改</el-button>
						<el-button type="danger" size="mini" plain @click="deleteItem(scope.row)">删除</el-button>
					</el-button-group>
				</template>
			</el-table-column>
		</el-table>
		<!-- 底部内容 -->
		<div style="height: 60px;"></div>
		<el-footer class="border-top d-flex align-items-center px-0 position-fixed bg-white" style="bottom: 0; left: 200px; right: 0;z-index: 100;">
			<!-- 底部分页 -->
			<div style="flex: 1;" class="px-2">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page.current"
				 :page-sizes="page.sizes" :page-size="page.size" layout="total, sizes, prev, pager, next, jumper" :total="page.total">
				</el-pagination>
			</div>
		</el-footer>
		<!-- 新增/修改模态框 -->
		<el-dialog :title="editIndex > -1 ? '修改规格' : '添加规格'" :visible.sync="createModel" top="5vh">
			<!-- 表单内容 -->
			<el-form ref="form" :model="form" label-width="80px" :rules="rules">

				<!-- 规格名称 -->
				<el-form-item label="规格名称" prop="name">
					<el-input v-model="form.name" placeholder="规格名称" size="mini" style="width: 25%;"></el-input>
				</el-form-item>

				<!-- 排序 -->
				<el-form-item label="排序">
					<el-input-number v-model="form.order" :min="0" size="mini"></el-input-number>
				</el-form-item>

				<!-- 状态 -->
				<el-form-item label="状态">
					<el-radio-group v-model="form.status" size="mini">
						<el-radio :label="1" border>启用</el-radio>
						<el-radio :label="0" border>禁用</el-radio>
					</el-radio-group>
				</el-form-item>

				<!-- 类型 -->
				<el-form-item label="类型">
					<el-radio-group v-model="form.type" size="mini">
						<el-radio :label="0" border>文字</el-radio>
						<el-radio :label="1" border>颜色</el-radio>
						<el-radio :label="2" border>图片</el-radio>
					</el-radio-group>
				</el-form-item>

				<!-- 规格值 -->
				<el-form-item label="规格值" prop="default">
					<el-input type="textarea" :rows="2" placeholder="一行为一个规格项,多个规格项目用换行输入" v-model="form.default">
					</el-input>
				</el-form-item>
			</el-form>

			<div slot="footer" class="dialog-footer">
				<el-button @click="createModel = false">取 消</el-button>
				<el-button type="primary" @click="submit">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import buttonSearch from "@/components/common/button-search.vue" // 引入 按钮、搜索、高级搜索组件
	import common from "@/common/mixins/common.js" // 引入 全局共公样式
	export default {
		inject: ['layout'], // 注入layout.vue
		mixins: [common], // 引入mixins里的common.js
		// 引入注册
		components: {
			buttonSearch
		},
		data() {
			return {
				preUrl:"skus", //api
				
				tableData: [],
				createModel: false,
				editIndex: -1,
				form: {
					name: "",
					order: 50,
					status: 1,
					type: 0,
					default: ""
				},
				// 表单验证规则
				rules: {
					name: [{
						required: true,
						message: '规格名称不能为空',
						trigger: 'blur',
					}],
					default: [{
						required: true,
						message: '规格值不能为空',
						trigger: 'blur',
					}],
				}
			}
		},
		
		// 生命周期监听页面
		methods: {
			// 获取列表 可修改
			getListResult(e){
				this.tableData = e.list
			},
			
			// 打开模态框
			openModel(e = false) {
				// 增加新规格
				if (!e) {
					// 初始化表单
					this.form = {
						name: "",
						order: 50,
						status: 1,
						type: 0,
						default: ""
					}
					this.editIndex = -1
				} else {
					// 修改规格
					this.form = {
						name: e.row.name,
						order: e.row.order,
						status: e.row.status,
						type: e.row.type,
						default: e.row.default.replace(/,/g, '\n')
					}
					this.editIndex = e.$index
				}
				// 打开模态框
				this.createModel = true
			},
			// 添加规格
			submit() {
				this.$refs.form.validate(res => {
					if (res) {
						this.form.default = this.form.default.replace(/\n/g, ',')
						let id = 0
						if (this.editIndex !== -1) {
							id = this.tableData[this.editIndex].id
						} 
						// 增加/修改 调用mixins中的js
						this.addOrEdit(this.form,id)
						// 关闭模态框
						this.createModel = false
					}
				})
			},
		}
	}
</script>

<style>
</style>
